/* eslint-disable @typescript-eslint/no-unused-vars */

import { request } from '@/src/lib/api/v3/client/core/request';
import { OpenAPI } from '@/src/lib/api/v3/client/core/OpenAPI';
import {
  CampaignAdminBarInfo,
  CampaignContributionsResponse,
  CampaignDonationProps,
  CampaignViewProps,
  DonorsProps,
  GivingLevelsProps,
  InstagramShareImages,
  ProjectStatePayload,
  RequirementsCheck,
  ShareLinks,
  TimelineProps,
} from '@/src/types';
import {
  campaignQueryParams,
  CampaignResponse,
} from '@/src/app/(views)/fundraising/dashboard/components/Campaign.interface';
import config from '@/src/config';
import { general_api_response_body } from '../../api/v3/client';
import {
  Campaign,
  CampaignStatus,
  Entity,
  OpenSearchService,
  SimpleResponse,
  SortFields,
} from '../../api/opensearch/client';

const campaignSearchApiUrl = `${config().lg3OpenSearchUrl}/cards/campaign`;

export class CampaignService {
  private static getToken() {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Invalid authorization');
    }

    return token;
  }

  public static getCampaignDetails = async (
    slug: string,
  ): Promise<
    general_api_response_body & {
      result?: CampaignViewProps;
    }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/view',
      body: { slug },
    });
  };

  public static getDonations = async (
    id: number,
  ): Promise<
    general_api_response_body & {
      result?: CampaignDonationProps;
    }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/statsPanel',
      body: { id },
    });
  };

  public static getTimeline = async (
    slug: string,
  ): Promise<
    general_api_response_body & {
      result?: TimelineProps;
    }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/timeline',
      body: { slug },
    });
  };

  public static getRewards = async (
    id: number,
  ): Promise<
    general_api_response_body & {
      result?: GivingLevelsProps[];
    }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/rewards',
      body: { id },
    });
  };

  public static getDonors = async (
    id: number,
    lastId = 0,
  ): Promise<
    general_api_response_body & {
      result?: DonorsProps;
    }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/donors',
      body: { id, lastId: lastId },
    });
  };

  public static getShareData = async (id: number): Promise<ShareLinks> => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/shareInfo',
      body: { id },
    });
  };

  public static getLiveCampaigns = async (
    accountId: number,
    limit = 3,
  ): Promise<Campaign[]> => {
    const token = this.getToken();

    const data = await OpenSearchService.getCardsByAccountId<Campaign>(
      Entity.CAMPAIGN,
      accountId,
      limit,
      undefined,
      token,
      CampaignStatus.ACTIVE,
      SortFields.END_DATE,
    );

    return data.results;
  };

  public static getCampaignsByAccount = async (
    accountId: number,
    queryParam: campaignQueryParams = {},
  ): Promise<SimpleResponse<Campaign>> => {
    const token = this.getToken();

    const {
      aggregateStatuses,
      limit,
      name,
      offset,
      q,
      searchAfter,
      sort,
      sortDirection,
      status,
    } = queryParam;

    const data = await OpenSearchService.getCardsByAccountId<Campaign>(
      Entity.CAMPAIGN,
      accountId,
      limit,
      offset,
      token,
      status,
      sort,
      sortDirection,
      q,
      aggregateStatuses,
      name,
      searchAfter,
    );

    return data;
  };

  public static getCampaignsAggregateByAccount = async (
    accountId: number,
  ): Promise<SimpleResponse<Campaign>> => {
    const token = this.getToken();
    const data = await OpenSearchService.getCardsByAccountId<Campaign>(
      Entity.CAMPAIGN,
      accountId,
      0,
      undefined,
      token,
      undefined,
      undefined,
      undefined,
      undefined,
      true,
    );

    return data;
  };

  public static getCampaignsByIds = async (
    campaignIds: number[],
    limit = 100,
  ): Promise<CampaignResponse> => {
    // @todo: change it from openAPi generated from docs
    const res = await fetch(
      `${
        config().lg3OpenSearchUrl
      }/cards/campaigns/by-id-list?limit=${limit}&id_list=${campaignIds.join(
        ',',
      )}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
      },
    );
    const data = await res.json();
    return data;
  };

  public static getInstagramShareImages = async (
    slug: string,
  ): Promise<
    general_api_response_body & {
      result: InstagramShareImages;
    }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/instagramShare',
      body: { slug },
    });
  };

  public static requirementsCheck = async (
    id: number,
  ): Promise<general_api_response_body & { result: RequirementsCheck }> => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectadmin/requirementsCheck',
      body: { id },
    });
  };

  public static changeProjectState = async (
    payload: ProjectStatePayload,
  ): Promise<general_api_response_body & { result: CampaignAdminBarInfo }> => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectadmin/changeProjectState',
      body: { ...payload },
    });
  };

  public static getCampaignContributions = async (payload: {
    projectID: number;
  }): Promise<
    general_api_response_body & { result: CampaignContributionsResponse }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/user/donationInfo',
      body: payload,
    });
  };
}
